import React from 'react'
import styled from 'styled-components'
import { Button } from '../components/Button/Button'
import Contact from '../components/Contact/Contact'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

const contact = () => {
  const Address = styled.div`
    margin: 20px auto;
    width: 90%;
    max-width: 1200px;
  `
  const GetDirections = styled.div`
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
  `

  return (
    <Layout>
      <Seo
        title={'Contact'}
        description="Contact Page"
        pathname="/web-appications/"
        image="/images/image4.jpg"
        date={'2023-01-30'}
      />
      <Contact />
    </Layout>
  )
}

export default contact 
